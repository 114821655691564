.app {
  background-color: #282c34;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 50px auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  min-height: 100vh;  
}

.app-logo {
  z-index: 1;
  background-color: var(--text-color);
  grid-column: 1 / 2;
  text-align: center;
}

.app-language {
  z-index: 1;
  background-color:transparent;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 22px;
  margin-right: 30px;;
}

.logo {
  height: 40px;
  pointer-events: none;
}

.app-body {
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: span 2;
  align-self: center;
  justify-self: center;
  min-width: 40vw;
}

.app-available-time {
  z-index: 1;
  background-color:transparent;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 22px;
  margin-right: 30px;;
}

.app-return-to-start-footer {
  z-index: 1;
  background-color:transparent;
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 22px;
  margin-right: 30px;;
}

